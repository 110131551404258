<template>
  <app-layout>
    <template v-slot:header> Plati vanzatori </template>
    <page-section-header>
      <template v-slot:actions> </template>
    </page-section-header>
    <v-card>
      <loader v-if="loading" class="my-5" />
      <template v-else>
        <template v-if="totalRows">
          <vendor-payments-index-table
            :rows="rows"
            @update:status="updateVendorPayment"
          />
          <pagination
            @change:page="index($event)"
            :total="meta.total"
            :current_page="meta.current_page"
            :from="meta.from"
            :to="meta.to"
            :next_page_url="links.next"
            :prev_page_url="links.prev"
          />
        </template>
        <no-results v-else class="m-5" />
      </template>
    </v-card>
  </app-layout>
</template>

<script>
import vendorPaymentsApi from "../api/resources/vendorPaymentsApi";
import VendorPaymentsIndexTable from "../components/VendorPaymentsIndexTable.vue";
import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import PageSectionHeader from "../components/ui/PageSectionHeader.vue";

export default {
  components: {
    VendorPaymentsIndexTable,
    AppLayout,
    VCard,
    Loader,
    Pagination,
    NoResults,
    PageSectionHeader,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    updateVendorPayment(vendorPayment) {
      this.rows.forEach((row) => {
        if (
          row.id === vendorPayment.id &&
          row.status.id !== vendorPayment.status.id
        ) {
          this.index();
        }
      });
    },

    index(page = null) {
      this.loading = true;
      vendorPaymentsApi
        .index(page && { page })
        .then((response) => {
          this.rows = response.data;
          this.meta = response.meta;
          this.links = response.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
